import React, { useEffect, useState } from 'react'
import './Product.css'

function Product() {

  return (
    <section className="c-product">

      <div className="c-space-divider"></div>

      asdf
     
    </section>
  )
}

export default Product